// Our main CSS
import '../css/app.css'

import './SiteMenu.js'

// FAQ Accordion
const accordionItems = document.querySelectorAll('.faq-accordion-item')

accordionItems.forEach((item) => {
  item.addEventListener('click', () => {
    const content = item.querySelector('.faq-accordion-content')
    item.classList.toggle('faq-accordion-item-active')

    if (item.classList.contains('faq-accordion-item-active')) {
      content.style.maxHeight = content.scrollHeight + 16 + 'px'
      content.style.paddingBottom = '16px'
    } else {
      content.style.maxHeight = 0
      content.style.paddingBottom = 0
    }
  })
})
