// *** DOM ELEMENTS ***
const elements = {
  body: document.body,
  header: document.querySelector('.site-header'),
  menuIcon: document.getElementById('menu-icon'),
  mobileExpandIcons: document.querySelectorAll('.expand-icon'),
  mobileMenu: document.getElementById('mobile-menu'),
  mobileMenuItems: document.querySelectorAll('.mobile-menu-item'),
  dropdown: document.querySelector('.desktop-dropdown'),
  langSwitch: document.getElementById('lang-switch'),
  langMenu: document.getElementById('lang-menu'),
  activeDesktopMenuItem: document.querySelector('.desktop-menu-item.active-item')
}

// *** STATE ***
let state = {
  dropdownActive: false,
  menuOpen: false,
  timeout: null
}

// *** EVENT HANDLERS ***
const handlers = {
  toggleSubmenu(menuItem) {
    menuItem.classList.toggle('submenu-open')
  },
  toggleMenu() {
    state.menuOpen = !state.menuOpen
    elements.header.classList.toggle('open')
    elements.body.style.overflow = state.menuOpen ? 'hidden' : 'visible'
  },
  handleMenuItemMouseOver(menuItem) {
    if (elements.langMenu.classList.contains('lang-open')) {
      elements.langMenu.classList.remove('lang-open')
    }

    if (menuItem.classList.contains('has-subitems')) {
      if (elements.activeDesktopMenuItem && elements.activeDesktopMenuItem !== menuItem) {
        elements.activeDesktopMenuItem.classList.remove('active-item')
      }
      elements.activeDesktopMenuItem = menuItem
      menuItem.classList.add('active-item')
      state.dropdownActive = true
      elements.dropdown.classList.add('active-dropdown')
    } else {
      if (elements.activeDesktopMenuItem)
        elements.activeDesktopMenuItem.classList.remove('active-item')
      elements.activeDesktopMenuItem = null
      state.dropdownActive = false
      elements.dropdown.classList.remove('active-dropdown')
    }
  },
  handleLangSwitchClick() {
    elements.activeDesktopMenuItem = elements.langSwitch
    elements.langSwitch.classList.toggle('active-item')
    state.dropdownActive = true
    elements.dropdown.classList.toggle('active-dropdown')
    elements.langMenu.classList.toggle('lang-open')
  },
  handleDropdownMouseOver() {
    state.dropdownActive = true
    elements.dropdown.classList.add('active-dropdown')
  },
  handleDropdownMouseLeave(e) {
    if (e.clientY < 100) return
    state.dropdownActive = false
    elements.dropdown.classList.remove('active-dropdown')
    if (elements.activeDesktopMenuItem) {
      elements.activeDesktopMenuItem.classList.remove('active-item')
      elements.activeDesktopMenuItem = null
    }
    if (elements.langMenu.classList.contains('lang-open')) {
      elements.langMenu.classList.remove('lang-open')
    }
  },
  handleWindowMouseOut(event) {
    if (elements.langMenu.classList.contains('lang-open')) {
      elements.langMenu.classList.remove('lang-open')
    }
    state.dropdownActive = false
    elements.dropdown.classList.remove('active-dropdown')
    if (elements.activeDesktopMenuItem) {
      elements.activeDesktopMenuItem.classList.remove('active-item')
      elements.activeDesktopMenuItem = null
    }
  }
}

// *** INITIALIZATION ***
const init = () => {
  elements.menuIcon.addEventListener('click', handlers.toggleMenu)

  elements.mobileMenuItems.forEach((menuItem) => {
    if (menuItem.classList.contains('has-subitems')) {
      menuItem.querySelector('.expand-icon').addEventListener('click', (e) => {
        e.preventDefault()
        handlers.toggleSubmenu(menuItem)
      })
    }
  })

  document.querySelectorAll('.desktop-menu-item').forEach((menuItem) => {
    menuItem.addEventListener('mouseover', () => handlers.handleMenuItemMouseOver(menuItem))
  })

  elements.langSwitch.addEventListener('click', handlers.handleLangSwitchClick)
  elements.dropdown.addEventListener('mouseover', handlers.handleDropdownMouseOver)
  elements.dropdown.addEventListener('mouseleave', handlers.handleDropdownMouseLeave)
  document.addEventListener('mouseleave', handlers.handleWindowMouseOut)
}

init()
